import React, { Component } from 'react';
import { rootStore, constants, serviceFactory } from 'cv-react-core';

import AppLayout from '../components/layouts/AppNavigation/AppNavigation';
import routeNames from './routeNames';
import Navigator from './Navigator';

const { WORKBENCH } = routeNames;

class NavRoute extends Component {
    constructor(props) {
        super(props);
        const { sessionStore } = rootStore;
        const { workbenches } = sessionStore;
        this.targets = {};

        // Cycle through the workbenches to create remaining targets
        for (let i = 0; i < workbenches.length; i += 1) {
            const workbench = workbenches[i];
            this.targets[workbench.id] = {
                route: WORKBENCH,
                params: { workbenchId: workbench.id },
                title: workbench.name,
            };
        }
    }
    render() {
        const navigator = new Navigator(this.props);
        return (
            <AppLayout
                targets={ this.targets }
                defaultRouteName={ WORKBENCH }
                navigator={ navigator }
                testID="app-layout" />
        );
    }

    componentDidMount() {
        const {
            uiStore,
        } = rootStore;

        const deviceProperties = {
            ...serviceFactory.device.deviceSize,
            GPSSupported: 'false',
            platform: 'browser-react',
        };
        uiStore.setValueForUIObject(
            constants.ui.APPLICATION_UI_ID,
            constants.ui.DEVICE_PROPERTIES,
            deviceProperties,
            false,
        );
    }
};

export default NavRoute;
