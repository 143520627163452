/* eslint-disable react/prefer-stateless-function */
import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { engineConstants } from 'cv-react-core';

import ActivityIndicator from '../components/base/ActivityIndicator';
import Calendar from '../components/base/Calendar';
import CvContextMenu from '../components/menu/CvContextMenu';
import SimpleModal from '../components/modal/SimpleModal';
import RWSaltComponent from './RWSaltComponent';
import MenuItem from '../components/base/MenuItem';

import lang from '../nls/i18n';

class RWCalendar extends RWSaltComponent {
    static propTypes = {
        /** Appointment array of objects to show events on calendar */
        appointments: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            startDate: PropTypes.instanceOf(Date),
            endDate: PropTypes.instanceOf(Date),
        })),

        /** Counter with dates and respective number of events per day */
        appointmentCounter: PropTypes.objectOf(PropTypes.number),

        /** Array of menu items to show on right click */
        availableMenuItems: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),

        /** Default current date to show on render */
        defaultDate: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),

        /** Default view to show on render */
        defaultView: PropTypes.oneOf([
            'Day',
            'Week',
            'Month',
        ]),

        /** Date picked on the fixed date picker */
        pickedDate: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),

        firstDayOfWeek: PropTypes.number,

        /** Locale to specify the format the timeline should be displayed */
        locale: PropTypes.string,

        /** Styles for this component container */
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),

        /** Styles for this component container */
        xStyle: PropTypes.oneOfType([
            // PropTypes.arrayOf(PropTypes.object),
            PropTypes.object,
        ]),

        /** Boolean value to show/hide a loading page while the records are fetched */
        isLoading: PropTypes.bool,

        /** Handler method when an appointment is dropped */
        onAppointmentUpdate: PropTypes.func,

        /** Handler method on confirm appointment drop */
        onConfirm: PropTypes.func,

        /** Handler method on cancel appointment drop */
        onCancel: PropTypes.func,

        /** Handler methods for click event */
        onEventClick: PropTypes.func,

        /** Handler method for menu item click */
        onMenuAction: PropTypes.func,

        /** Handler method for double click event */
        onEventDoubleClick: PropTypes.func,

        /** Event to handle current date change using date navigator */
        onDateChange: PropTypes.func,

        /** Event to handle when view changes between day, week and month */
        onViewChange: PropTypes.func,

        /** Handler method for date picker year/month change */
        onPickerChange: PropTypes.func,

        /** Handler method for clicking left/previous on the calendar */
        onPrevious: PropTypes.func,

        /** Handler method for clicking right/next on the calendar */
        onNext: PropTypes.func,

        /** Event triggered when a call is clicked in month view */
        onMonthCellClick: PropTypes.func,

        /** Event triggered when a cell is double clicked in month view */
        onMonthCellDoubleClick: PropTypes.func,

        /** Boolean value to show a confirmation popup when an appointment is dropped */
        showConfirmation: PropTypes.bool,

        /** Extended Menu - Additional available actions that may be OPTIONALLY shown elsewhere (i.e. toolbar) */
        xMenu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
        })),
    };

    constructor(props) {
        super(props);
        this.contextMenuRef = React.createRef();
        this.menuItems = [];
    }

    componentDidMount() {
        const {
            availableMenuItems,
            onMenuAction,
            xMenu,
        } = this.props;

        const extendedMenu = xMenu.find((f) => (f.id === engineConstants.action.clientActions.copyToClipboard));
        this.extendedMenuItems = [ ...availableMenuItems ];

        if (extendedMenu) {
            this.extendedMenuItems.push({
                ...extendedMenu,
                menuText: lang.list.copyToClipboard,
            });
        }
        this.menuItems = this.extendedMenuItems.map((item) => (
            <MenuItem
                key={ `${item.id}${item.menuText}` }
                id={ item.id }
                onClick={ () => {
                    if (onMenuAction) {
                        onMenuAction(item);
                    }
                } }
                text={ item.menuText } />
        ));
    }

    get contextMenu() {
        return this.contextMenuRef;
    }

    handleEventContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const {
            clientX,
            clientY,
            currentTarget,
        } = event;
        const {
            onEventClick,
        } = this.props;
        const objectId = currentTarget.getAttribute('objectid');
        if (objectId) {
            onEventClick({
                data: {
                    objectId,
                },
            });
        }
        this.contextMenu.current.show({ top: clientY, left: clientX });
        return false;
    }

    render() {
        const {
            appointments,
            allDayCounter,
            defaultView,
            defaultDate,
            pickedDate,
            xStyle,
            isLoading,
            onAppointmentUpdate,
            onConfirm,
            onCancel,
            onDateChange,
            onViewChange,
            onPickerChange,
            onPrevious,
            onNext,
            onMonthCellClick,
            onMonthCellDoubleClick,
            onEventClick,
            onEventDoubleClick,
            firstDayOfWeek,
            showConfirmation,
            locale,
        } = this.props;
        let filteredAppointments;
        if (!defaultView || defaultView === 'Month') {
            filteredAppointments = appointments.filter((appointment) => appointment.counter && Math.max(...Object.values(appointment.counter)) <= 5);
        }
        return (
            <Fragment>
                { isLoading && <ActivityIndicator centered /> }
                <SimpleModal
                    contextStyles={ {} }
                    show={ !!showConfirmation }
                    onCancel={ onCancel }
                    onConfirm={ onConfirm }
                    title={ lang.calendar.updateConfirmation.title }
                    message={ lang.calendar.updateConfirmation.message }
                    yesText={ lang.calendar.updateConfirmation.confirm }
                    noText={ lang.calendar.updateConfirmation.cancel } />
                <CvContextMenu
                    ref={ this.contextMenuRef }>
                    { this.menuItems }
                </CvContextMenu>
                <Calendar
                    appointments={ filteredAppointments || appointments }
                    allDayCounter={ allDayCounter }
                    defaultDate={ defaultDate }
                    defaultView={ defaultView }
                    pickedDate={ pickedDate }
                    contextStyles={ xStyle }
                    firstDayOfWeek={ firstDayOfWeek }
                    locale={ locale }
                    onAppointmentUpdate={ onAppointmentUpdate }
                    onDateChange={ onDateChange }
                    onViewChange={ onViewChange }
                    onPickerChange={ onPickerChange }
                    onPrevious={ onPrevious }
                    onNext={ onNext }
                    onMonthCellClick={ onMonthCellClick }
                    onMonthCellDoubleClick={ onMonthCellDoubleClick }
                    onEventContextMenu={ this.handleEventContextMenu }
                    onEventClick={ onEventClick }
                    onEventDoubleClick={ onEventDoubleClick } />
            </Fragment>
        );
    }
}

export default RWCalendar;