import React, {Fragment} from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { rootStore, constants, workbenchController } from 'cv-react-core';

import Route from './Route';
import lang from '../nls/i18n';
import routeNames from './routeNames';
import Navigator from './Navigator';
import WorkbenchPage from '../components/pages/WorkbenchPage/WorkbenchPage';
import recursiveRouteManager from './RecursiveRouteManager';
import LoadingIndicator from '../components/modal/LoadingIndicator';
import ErrorRouteModal from '../components/modal/ErrorRouteModal';
import LaunchersDrawer from '../components/pages/WorkbenchPage/LaunchersDrawer';

const {
    ui,
} = constants;
const {
    WORKBENCH_UI_OBJECT_ID,
} = ui;
const {
    WORKBENCH,
} = routeNames;

@observer
class WorkbenchRoute extends Route {
    static propTypes = {
        onOpenLauncher: PropTypes.func,
        navigator: PropTypes.instanceOf(Navigator),
        showDrawer: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const { sessionStore } = rootStore;
        const { navigator } = props;
        const { workbenchId } = navigator.match.params;
        sessionStore.setSelectedWorkbenchById(workbenchId);
    }

    render() {
        const {
            sessionStore,
            uiStore,
        } = rootStore;
        const {
            selectedWorkbenchStore,
        } = sessionStore;
        const {
            navigator,
            showDrawer,
        } = this.props;

        // Set browser tab name to the workbench name.
        // Doing this on each route for consistency for now.
        const { workbench, filteredActions } = selectedWorkbenchStore;
        if (navigator.match.params.route === WORKBENCH) {
            document.title = workbench.name;
        }
        const loadingProps = {
            objectId: WORKBENCH_UI_OBJECT_ID,
            uiStore,
        };

        let component;
        // We can show launchers either in full page or in Side Drawer.
        if (showDrawer) {
            const launcherDrawerProps = {
                activeLauncher: recursiveRouteManager.getActiveLauncherId(),
                launchers: filteredActions,
                navigator,
                onOpenLauncher: (launcher, modifiers) => this.handleOnOpenLauncher(selectedWorkbenchStore, launcher, false, modifiers),
            };
            component = <LaunchersDrawer { ...launcherDrawerProps } />;
        }
        else {
            component = (
                <WorkbenchPage
                    navigator={ navigator }
                    workbenchStore={ selectedWorkbenchStore }
                    onOpenLauncher={ this.handleOnOpenLauncher }
                    onTransition={ this.handleTransition }
                    onError={ this.handleError } />
            );
        }
        return (
            <Fragment>
                { React.createElement(LoadingIndicator, { ...loadingProps }) }
                { React.createElement(ErrorRouteModal, { ...loadingProps }) }
                { component }
            </Fragment>
        );
    }

    componentDidMount() {
        const {
            uiStore,
            settingsStore,
            sessionStore } = rootStore;
        const dl = settingsStore.getValue('DEEP_LINKING_ID');
        if (dl) {
            this.handleDeeplink(dl);
        }
        workbenchController.clearGloballyBusy(WORKBENCH_UI_OBJECT_ID, uiStore);
        const oAuthToken = sessionStore.getOAuthToken();
        if (oAuthToken) {
            const { id: sessionId, tenantId } = sessionStore.session;
            workbenchController.performWorkbenchOAuthCallBackCall(tenantId, sessionId, uiStore, oAuthToken, this.handleTransition, null, constants.clientType.DESKTOP);
        }
    }

    handleOnOpenLauncher = (workbenchStore, launcher, isInitialLaunchAction, modifiers) => {
        const { uiStore, settingsStore } = rootStore;
        if (settingsStore.getValue('DEEP_LINKING_ID') && isInitialLaunchAction) return;
        workbenchController.performWorkbenchAction(uiStore, workbenchStore, this.handleTransition, this.handleError, launcher, isInitialLaunchAction, modifiers);
        const { onOpenLauncher } = this.props;
        if (onOpenLauncher) onOpenLauncher();
    }

    handleDeeplink = (dl) => {
        const { settingsStore, sessionStore } = rootStore;
        sessionStore.performDeepLink(dl)
        .then((dialogOrRedirection) => {
            settingsStore.setValue('DEEP_LINKING_ID', null);
            this.handleTransition(dialogOrRedirection);
        })
        .catch((err) => {
            const error = {
                title: lang.dialog.errors.errorOpenDialogTitle,
                err,
                type: constants.error.errorTypes.soft,
            };
            settingsStore.setValue('DEEP_LINKING_ID', null);
            this.handleError(error);
        });
    }
}

export default WorkbenchRoute;
