import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { rootStore, constants, BuildGlobals, loginController } from 'cv-react-core';
import WebFont from 'webfontloader';
import { Log } from 'cv-dialog-sdk';
import route from './routing/router';
import routeNames from './routing/routeNames';
import LoadingPage from './components/pages/LoadingPage';
import TestComponent from './testPages';
import { getNavigationPathTenant } from './utilities/utilities';
import ErrorBoundary from './components/error/ErrorBoundary';

import 'cv-library-react-web/src/components/global.css';
import './styles/App.css';
import 'react-toastify/dist/ReactToastify.css';

@observer
class App extends Component {
    state = {
        isLoading: true,
    };

    render() {
        if (BuildGlobals.getLogLevel() === 'DEBUG' && BuildGlobals.getTestComponent().length > 0) {
            return <TestComponent />;
        }

        // PLEASE NOTE: This is confusing code. Since our app is routing based on observations from our stores, we do not control routing
        // in this render method, but we fire routing. The state value isLoading is being used to know the component has mounted and to then
        // transition to login page
        const { isLoading } = this.state;

        if (isLoading) {
            return (
                <ErrorBoundary errorMessage="Our apologies - something unexpected happened">
                    <LoadingPage />
                </ErrorBoundary>
            );
        }
        return (
            <ErrorBoundary errorMessage="Our apologies - something unexpected happened">
                { route() }
            </ErrorBoundary>
        );
    }

    componentDidMount() {
        this.startApp();
    }

    /**
     * Method for initializing the application for routing.
     */
    startApp() {
        // Setup global fallback for fonts
        this.setUpFonts();

        // 1. Refresh user from local Storage
        // 2. Attempt to automatically login
        // 3. Otherwise route to login

        rootStore.appRestoredPromise.then(() => {
            const { sessionStore, settingsStore } = rootStore;

            const routePath = getNavigationPathTenant();
            // We want to split the paths incase we are doing a refresh. The first route should indicate
            // if we can try and login automatically again or not.
            const routePaths = routePath.split('/');
            const routeTenantId = routePaths[0];

            // If the route contains the existing tenant, we can try and log back in
            const { tenantId } = sessionStore.session || { tenantId: ''};

            // If our route tenant matches our session tenant, try and login
            if (routeTenantId === tenantId && tenantId.length > 0) {
                // Refresh session to see if we can automatically login again.
                this.refreshSessionAttempt(settingsStore, sessionStore, tenantId);
            }
            else {
                // Setup the login route protocol.
                this.setupLoginRoute(settingsStore, sessionStore, routeTenantId);
            }
        });
    }

    // @TODO: Move this process up as a service for processing font defaults
    setUpFonts = () => {
        const { themeStore } = rootStore;
        const theme = themeStore.getSanitizedTheme();
        const { fontFamily } = theme.fonts.default;
        WebFont.load({
            google: {
                families: [ fontFamily ],
            },
        });
    }

    /**
     * Method to refresh the user session so the route can attempt to login
     * and navigate to the user workbenches.
     * @param {object} settingsStore
     * @param {object} sessionStore
     * @param {string} tenantId
     * @returns tenantCapabilities
     */
    refreshSessionAttempt = (settingsStore, sessionStore, tenantId) => {
        const catchError = (error) => { this.onCatchError(error); };
        return this.setupTenantFromRoute(tenantId, settingsStore, sessionStore)
                .then(() => {
                    if (sessionStore.session && sessionStore.session.tenantId) {
                        const params = new URLSearchParams(window.location.search);
                        const sessionHandle = params.get('sessionHandle');
                        const activeSession = sessionHandle || sessionStore.session.id;
                        const tenantID = params.get('tenantid');
                        const activeTenantId = tenantID || sessionStore.session.tenantId;
                        return sessionStore.refreshSession(activeTenantId, activeSession)
                            .then(() => {
                                this.setDeepLinkingId(settingsStore);
                                this.setApplicationLoaded();
                            })
                            .catch(catchError);
                    }
                    // No session to refresh to log back in. Just go to login page.
                    return this.setApplicationLoaded();
                })
                .catch(catchError);
    }

    /**
     * Method to setup the observables for routing to login page.
     * @param {object} settingsStore
     * @param {object} sessionStore
     * @param {string} tenantId
     */
    setupLoginRoute = (settingsStore, sessionStore, tenantId) => {
        const catchError = (error) => { this.onCatchError(error); };
        loginController.isValidTenantId(tenantId, sessionStore, constants.clientType.DESKTOP)
            .then((isValid) => {
                if (isValid) {
                    this.setupTenantFromRoute(tenantId, settingsStore, sessionStore)
                        .then(() => {
                            const params = new URLSearchParams(window.location.search);
                            const sessionHandle = params.get('sessionHandle');
                            if (sessionHandle) {
                                return sessionStore.refreshSession(tenantId, sessionHandle)
                                .then(() => {
                                    this.setDeepLinkingId(settingsStore);
                                    this.setApplicationLoaded();
                                })
                                .catch(catchError);
                            }
                            return this.setApplicationLoaded();
                        })
                        .catch(catchError);
                }
                else {
                    const skipTenantValidation = !!(tenantId === routeNames.LOGIN || tenantId === routeNames.SETTINGS || tenantId === '');
                    if (skipTenantValidation) {
                        this.setApplicationLoaded();
                    }
                    else {
                        settingsStore.setValue('INVALID_TENANT_URL', true);
                        settingsStore.setValue('INVALID_TENANT_ID_USED', tenantId);
                        this.setApplicationLoaded();
                    }
                }
            })
            .catch(catchError);
    }

    /**
     * Method to set the observables for tenant capabilities and style
     * @param {string} tenantId
     * @param {object} settingsStore
     * @param {object} sessionStore
     * @returns tenantCapabilities
     */
    setupTenantFromRoute = (tenantId, settingsStore, sessionStore) => {
        settingsStore.setValue(constants.settings.TENANT_ID, tenantId);
        return loginController.applyTenantCapabilities(tenantId, sessionStore, constants.clientType.DESKTOP);
    }

    /**
     * Method to set the observables that the component mounted and is ready to route.
     */
    setApplicationLoaded = () => {
        this.setState({
            isLoading: false,
        });
    }

    setDeepLinkingId = (settingsStore) => {
        const params = new URLSearchParams(window.location.search);
        const deeplinkingID = params.get('dl');
        if (deeplinkingID) {
            settingsStore.setValue('DEEP_LINKING_ID', deeplinkingID);
        }
    }

    /**
     * Standard handler for promises to log the message as a warning and update
     * observables to navigate to the login page.
     * @param {object} error
     */
    onCatchError = (error) => {
        Log.warn(error.message);
        const params = new URLSearchParams(window.location.search);
        const deeplinkingID = params.get('dl');
        if (deeplinkingID) {
            const { settingsStore } = rootStore;
            settingsStore.setValue('DEEP_LINKING_ERROR', error.message);
        }
        this.setApplicationLoaded();
    }
}


export default App;
