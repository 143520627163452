import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
// import { trace } from 'mobx';
import { observer } from 'mobx-react';
import { DialogComponentBuilder, utilities, engineConstants, constants, serviceFactory } from 'cv-react-core';
import { DeTable } from 'cv-library-react-web';
import { Log } from 'cv-dialog-sdk';

import RWSaltComponent from './RWSaltComponent';
import TableRow from '../components/base/DevExpressTable/TableRow';
import TablePagingContainer from '../components/base/DevExpressTable/TablePagingContainer';
import TableScrollContainer from '../components/base/DevExpressTable/TableScrollContainer';
import TableSelectionTray from '../components/base/DevExpressTable/TableSelectionTray';
import TableHeaderCell from '../components/base/DevExpressTable/TableHeaderCell';
import TableHeaderCellContent from '../components/base/DevExpressTable/TableHeaderCellContent';
import TableHeaderCellStub from '../components/base/DevExpressTable/TableHeaderCellStub';
import TableSelectionCell from '../components/base/DevExpressTable/TableSelectionCell';
import LoadingTable from '../components/base/DevExpressTable/LoadingTable';
import CvContextMenu from '../components/menu/CvContextMenu';
import MenuItem from '../components/base/MenuItem';
import ListActivityIndicator from '../components/base/ListActivityIndicator/ListActivityIndicator';
import ColumnChooser from '../components/base/DevExpressTable/ColumnChooser';
import BaseTabelCell from '../components/base/DevExpressTable/BaseTabelCell';

const {
    annotationHelper,
    listHelper,
} = utilities;

/**
 * @todo Need to cleanup how styling is performed on all new table components
 */

const { searchSort } = constants;
const { DOT_REPLACEMENT } = searchSort;

const LIST_COLUMN_ORDER = 'LIST_COLUMN_ORDER';
const LIST_FIXED_COLUMNS = 'LIST_FIXED_COLUMNS';
const LIST_HIDDEN_COLUMNS = 'LIST_HIDDEN_COLUMNS';

const LIST_PAGE_OVERRIDE_INITIAL_SIZE = 'LIST_PAGE_OVERRIDE_INITIAL_SIZE';
const SelectionColumn = {
    name: 'selection_column',
    title: '',
    width: 35,
    columnOrder: 0,
    fixedColumn: true,
};

const SelectionColumnWidth = {
    columnName: SelectionColumn.name,
    width: SelectionColumn.width,
};

@observer
class RWGridTable extends RWSaltComponent {
    static propTypes = {
        asyncDataCallback: PropTypes.func,
        availableMenuItems: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),
        columns: PropTypes.array,
        columnWidths: PropTypes.array,
        fixedColumnCount: PropTypes.number,
        doubleClickForDefaultAction: PropTypes.bool,

        // onChooseItem implies intent to 'fire an action' or toggle selection, depending on the selection mode
        onChooseItem: PropTypes.func,
        onChooseNone: PropTypes.func,
        onMenuAction: PropTypes.func,
        onRefresh: PropTypes.func,
        onRequestMore: PropTypes.func,
        onSortColumn: PropTypes.func,
        onShowSortForm: PropTypes.func,
        isCustomSortEnabled: PropTypes.bool,

        // onSelectItem implies intent to force selection of the item (no action)
        onSelectItem: PropTypes.func,
        onGoToPage: PropTypes.func,
        onGoToNextPage: PropTypes.func,
        onGoToPreviousPage: PropTypes.func,
        onPageSizeChange: PropTypes.func,
        onSelectAll: PropTypes.func,
        orientation: PropTypes.string,
        recordDef: PropTypes.object,
        records: PropTypes.array,
        canPageBack: PropTypes.bool,
        canPageForward: PropTypes.bool,
        pagingItemTitle: PropTypes.string,

        recordsSelectionState: PropTypes.object,
        style: PropTypes.object,
        viewType: PropTypes.string,
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xMenu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
        })),
        dialogStore: PropTypes.object,
        uiStore: PropTypes.object,
        isRecordSelected: PropTypes.func,
        isSearchFormOpen: PropTypes.bool,
        isColumnFiltered: PropTypes.func,
        isListInitialized: PropTypes.bool,
        numberOfSelectedRecords: PropTypes.number,
        rowStyle: PropTypes.object,
        currentPageSize: PropTypes.number,
        currentPageNumber: PropTypes.number,
        pageSelectAll: PropTypes.bool,
        isPagingEnabled: PropTypes.bool,
        refreshInProgress: PropTypes.bool,
    };

    static defaultProps = {
        isSearchFormOpen: false,
        isCustomSortEnabled: false,
        availableMenuItems: [],
        onChooseItem: () => Promise.resolve(),
        onChooseNone: () => Promise.resolve(),
        onMenuAction: () => Promise.resolve(),
        onRefresh: () => Promise.resolve(),
        onRequestMore: () => Promise.resolve(),
        onSelectItem: () => Promise.resolve(),
        isRecordSelected: () => Promise.resolve(),
        style: {},
        xStyle: {},
        xMenu: [],
        doubleClickForDefaultAction: false,
        isListInitialized: false,
        isPagingEnabled: false,
        refreshInProgress: false,
    };

    constructor(props) {
        super(props);
        const {
            columns,
            uiStore,
            dialogStore,
            pageSizes,
            isPagingEnabled } = props;

        const { dialog } = dialogStore;
        this.dialogId = dialog.id;
        this.initializingLoad = true;
        this.listPageSize = uiStore.getValueForUIObject(this.dialogId, LIST_PAGE_OVERRIDE_INITIAL_SIZE);
        if (this.listPageSize && this.listPageSize > 50) {
            dialogStore.setIsListInitialized(false);
            dialogStore.overrideInitialPageSize = this.listPageSize;
        }
        if (isPagingEnabled) {
            const { view } = dialog;
            const { pagingPersistentId } = view;
            dialogStore.setIsListInitialized(false);
            dialogStore.overrideInitialPageSize = this.listPageSize;
            let persistentPageSize = uiStore.getValueForUIObject(pagingPersistentId, constants.ui.VIEW_PAGINATIONSETTINGS);
            // if persistedPageSize is part of the pagesizes returned from the dialog use it, else make first pagesize to default
            if (!persistentPageSize || !pageSizes.includes(persistentPageSize)) {
                persistentPageSize = pageSizes[0];
            }
            dialogStore.setPageSize(persistentPageSize);
            this.listPageSize = persistentPageSize;
        }
        this.errorCount = 0;
        this.errorCountThreshold = 30;

        // Instantiate Dialog Component Builder
        this.dialogComponentBuilder = new DialogComponentBuilder();
        this.columnNamesOriginalOrder = this.getColumnNamesOriginalOrder(columns);

        this.contextMenuRef = React.createRef();
        this.columnChooserRef = React.createRef();
        this.menuItems = [];
    }

    componentDidCatch(error, errorInfo) {
        // Handle uncaught errors
        const ERROR_MESSAGES = [
                'The columnWidths property of the TableColumnResizing plugin is given an invalid value.',
                'Cannot read property \'map\' of undefined',
            ];
        if (ERROR_MESSAGES.includes(error.message)) {
            // DANGER: THIS HAS THE POSSIBILITY TO CREATE AN INFINIT LOOP
            this.errorCount += 1;
            if (this.errorCount >= this.errorCountThreshold) {
                throw new Error(error);
            }
            // TODO: The issue here is the component is not being destroyed before this tryies to load on top of the existing grid.
            // Need to review DevExpress grid component to destroy before loading this agian.
            // These errors are caused by the internal state of Grid
            // Until we find better ways to handle these errors, we swallow the error and let the eventual rerenders fix the crash
            // Refer: Bug 15960: XHA: Application getting crashed on changing list views
            Log.error(error);
            Log.trace(errorInfo);
            /** **************** HACK ALERT HACK ALERT *******************************/
            /** **************** HACK ALERT HACK ALERT *******************************/
            /** **************** HACK ALERT HACK ALERT *******************************/
            /** 2020-09-30 Alan Resha
             * TODO: THIS NEEDS TO BE REMOVED AS SOON AS A FIX CAN BE FOUND
             * It is pretty consist for me that when the selector componet fials, this happens
             * Make me think that one, the selector component needs to be fixed to address the out-of-rage
             * issues that fire constently is resolved and then check to see if this is resolved. Then we
             * need to see why/how we are ever sending content to the grid that wouldn't allow for knowing it's size, this should never happen.
             * We should never swallow these message and not do anything.
             /** Adding a forced refresh so the customer doesn't have to do it. A simple refresh fixes the "Issue"
             */
            const { onRefresh } = this.props;
            onRefresh();
            /** **************** HACK ALERT HACK ALERT *******************************/
            /** **************** HACK ALERT HACK ALERT *******************************/
            /** **************** HACK ALERT HACK ALERT *******************************/
        }
        else {
            // This won't let other errors silently fail
            throw new Error(error);
        }
    }

    render() {
        // trace(true);
        const {
            dialogStore,
            onChooseNone,
            isListInitialized } = this.props;

        if ( isListInitialized ) {
            return (
                <Fragment>
                    { this.renderDataTable() }
                    <CvContextMenu
                        ref={ this.contextMenuRef }>
                        { this.menuItems }
                    </CvContextMenu>
                    <TableSelectionTray
                        dialogStore={ dialogStore }
                        onChooseNone={ onChooseNone } />
                </Fragment>
            );
        }

        const { lang } = serviceFactory;
        return React.createElement(LoadingTable, { ...this.props, overrideMessage: lang.list.loading });
    }

    renderDataTable = () => {
        // trace(true);
        const {
            columns,
            columnWidths,
            records,
            doubleClickForDefaultAction,
            style,
            isPagingEnabled,
            currentPageNumber,
            currentPageSize,
            refreshInProgress,
        } = this.props;

        const { lang } = serviceFactory;
        if (records.length === 0 && !refreshInProgress) {
            const overrideMessage = refreshInProgress ? lang.list.loading : lang.list.noRecords;
            return React.createElement(LoadingTable, { ...this.props, overrideMessage });
        }

        const columnData = this.getColumnData(columns, doubleClickForDefaultAction );
        if (!doubleClickForDefaultAction && columnWidths.length > 0) columnWidths.unshift(SelectionColumnWidth);
        return (
            <>
                <ColumnChooser
                    columns={ columnData }
                    onHiddenColumnsChange={ this.handleHiddenColumsChange }
                    ref={ this.columnChooserRef } />
                <DeTable
                    style={ style }
                    columnWidths={ columnWidths }
                    columnOrder={ this.getColumnOrder(doubleClickForDefaultAction) }
                    columns={ columnData }
                    fixedLeftColumns={ columnData.filter((c) => c.fixedColumn === true).map((f) => f.name) }
                    hiddenColumns={ columnData.filter((c) => !!c.hidden).map((f) => f.name) }
                    onColumnsReordered={ this.handleColumnsReordered }
                    rows={ this.getRowData(records, doubleClickForDefaultAction) }
                    testID="RWGridList"
                    tableContainer={ this.renderTableContainer }
                    tablePagingContainer={ refreshInProgress ? this.renderLoadingMoreRecordsIndicator : this.renderPagingContainer }
                    headerCell={ this.renderHeaderCell }
                    headerCellContentComponent={ this.renderHeaderCellContent }
                    tableSelectionComponent={ TableSelectionCell }
                    rowComponent={ this.renderRow }
                    headerCellStubComponent={ this.renderHeaderCellStub }
                    fixedColumnCell={ this.renderFixedColumn }
                    cellComponent={ this.renderCell }
                    currentPageSize={ currentPageSize }
                    currentPageNumber={ currentPageNumber }
                    isPagingEnabled={ isPagingEnabled } />
            </>
        );
    };

    componentDidMount() {
        this.initializingLoad = false;
        const {
            availableMenuItems,
            xMenu,
            onMenuAction,
        } = this.props;

        const { lang } = serviceFactory;
        const extendedMenu = xMenu.find((f) => (f.id === engineConstants.action.clientActions.copyToClipboard));
        this.extendedMenuItems = [ ...availableMenuItems ];

        if (extendedMenu) {
            this.extendedMenuItems.push({
                ...extendedMenu,
                menuText: lang.list.copyToClipboard,
            });
        }
        // Need to refactor how these menu's are created. We create menu's in too many places to show the same menu
        this.extendedMenuItems = this.extendedMenuItems.filter((item) => item.menuText !== undefined);
        this.menuItems = this.extendedMenuItems.map((item) => (
            <MenuItem
                key={ `${item.id}${item.menuText}` }
                id={ item.id }
                onClick={ () => {
                    if (onMenuAction && item.menuText) {
                        onMenuAction(item);
                    }
                } }
                text={ item.menuText || '' } />
        ));
    }

    renderHeaderCellStub = (baseProps) => React.createElement(TableHeaderCellStub, { ...baseProps });

    renderHeaderCell = (baseProps) => {
        const {
            onSortColumn,
            isSearchFormOpen,
            pageSelectAll,
            onSelectAll } = this.props;
        const tableHeaderCellProps = {
            onSortColumn,
            isSearchFormOpen,
            pageSelectAll,
            onColumnFrozen: this.handleColumnFrozen,
            onSelectAll,
            hearderBaseProps: baseProps };
        return React.createElement(TableHeaderCell, { ...tableHeaderCellProps });
    }

    renderHeaderCellContent = (baseProps) => {
        const {
            onSortColumn,
            onShowSortForm,
            isSearchFormOpen,
            isCustomSortEnabled,
        } = this.props;
        const { column } = baseProps;
        const contentProps = {
            onSortColumn,
            onShowSortForm,
            isSearchFormOpen,
            isCustomSortEnabled,
            onColumnFrozen: this.handleColumnFrozen,
            onColumnVisibility: this.handleColumnVisibility,
            ...column,
        };
        return React.createElement(TableHeaderCellContent, { ...contentProps });
    }

    renderTableContainer = (props) => {
        const {
            uiStore,
            dialogStore,
            isPagingEnabled,
        } = this.props;
        return (
            <TableScrollContainer
                dialogStore={ dialogStore }
                uiStore={ uiStore }
                isPagingEnabled={ isPagingEnabled }
                loadingIndicatorComponent={ this.renderLoadingMoreRecordsIndicator() }
                onRequestMore={ this.handleLoadMoreData }>
                { props.children }
            </TableScrollContainer>
        );
    }

    renderPagingContainer = () => {
        const {
            currentPageSize,
            currentPageNumber,
            pagesRetrieved,
            dialogStore,
            pageSizes,
            onPageSizeChange,
            onGoToPage,
            onGoToNextPage,
            onGoToPreviousPage,
            pagingItemTitle,
            canPageForward,
            canPageBack,
        } = this.props;
        return (
            <TablePagingContainer
                dialogStore={ dialogStore }
                pageSizes={ pageSizes }
                pageSize={ currentPageSize }
                pageNumber={ currentPageNumber }
                pagesRetrieved={ pagesRetrieved }
                canPageForward={ canPageForward }
                canPageBack={ canPageBack }
                onCurrentPageSizeChange={ onPageSizeChange }
                onGoToPage={ onGoToPage }
                onGoToNextPage={ onGoToNextPage }
                onGoToPreviousPage={ onGoToPreviousPage }
                pagingItemTitle={ pagingItemTitle } />
        );
    }

    renderLoadingMoreRecordsIndicator = () => <ListActivityIndicator />;

    renderRow = (baseProps) => {
        const {
            records,
            recordsSelectionState,
            rowStyle } = this.props;
        const { tableRow } = baseProps;
        const { rowId } = tableRow;
        const record = records[rowId];
        const { id } = record;
        const tableRowProps = {
            rowBaseProps: baseProps,
            onClick: this.handleRowSelectionChange,
            onDoubleClick: this.handleRowSelectionChange,
            onRowsLoaded: this.handleOnRowsLoaded,
            onRowContextMenu: this.handleContextMenu,
            recordsSelectionState,
            recordId: id,
            rowStyle,
        };

        return React.createElement(TableRow, { ...tableRowProps });
    }

    renderFixedColumn = (tableProps) => {
        const { records, recordsSelectionState } = this.props;

        const {
            tableRow,
            component: FixColumnComponent,
            tableColumn,
            style,
            position,
            ishovered,
            rowbackgroundcolor } = tableProps;
        const { column } = tableColumn;
        const { rowId } = tableRow;
        const record = records[rowId];
        const selected = record ? recordsSelectionState[record.id] : false;
        const fixedColumnProps = {
            column,
            style,
            position,
            tableRow,
            tableColumn,
            selected,
            rowbackgroundcolor,
            ishovered,
        };
        return React.createElement(FixColumnComponent, { ...fixedColumnProps });
    }

    // TODO: Starting point for lifting render into core list component after property refactoring is finished.
    renderCell = (props) => {
        const {
            column,
            tableRow,
            value,
            row,
            position,
            selected,
            style,
            ishovered,
            rowbackgroundcolor } = props;

        const { records } = this.props;

        const { rowId } = tableRow;
        const record = records[rowId];
        const { name, fixedColumn } = column;

        const propertyName = name.replace(DOT_REPLACEMENT, '.');
        const property = record && record.propAtName(propertyName) || {};

        const annotationBackgroundColor = selected ? undefined : annotationHelper.getBackgroundColor(null, property);
        const annotationRowBackgroundColor = selected ? undefined : annotationHelper.getBackgroundColor(record);

        /**
         *  Style order for setting background color for a record. Here is the order
         *  1. property annotation background color, when row is not selected
         *  2. row annotation background color applied if no property annotation provided and row is not selected
         *  3. provided rowbackgroundcolor for default background color or selection background color.
         * */
        const baseCellBackgroundColor = ishovered ? rowbackgroundcolor : (annotationBackgroundColor || annotationRowBackgroundColor || rowbackgroundcolor ) || 'unset';

        const baseCellProps = {
            key: `${record.id}-${column.name}`,
            rowId,
            column,
            onClick: this.handleRowSelectionChange,
            onDoubleClick: this.handleRowSelectionChange,
            row,
            value,
            position,
            backgroundColor: baseCellBackgroundColor,
            fixedColumn,
            selected,
            style,
            ishovered,
            fetchCellPropertyProps: this.handleFetchCellPropertyProps,
        };
        return React.createElement(BaseTabelCell, baseCellProps);
    }

    getColumnOrder = (isDoubleClickEnabled) => {
        const { uiStore } = this.props;
        const columnOrder = uiStore.getValueForUIObject(this.dialogId, LIST_COLUMN_ORDER) || this.columnNamesOriginalOrder;
        if (isDoubleClickEnabled) return columnOrder;
        if (columnOrder[0] === SelectionColumn.name) return columnOrder;
        const newOrder = [
            SelectionColumn.name,
            ...columnOrder,
        ];
        return newOrder;
    }

    getColumnNamesOriginalOrder = (columns) => {
        const { uiStore } = this.props;
        const fixedLeftColumns = [];
        const normalColumns = [];
        columns.forEach((col) => {
            const columnName = col.propertyName.replace('.', DOT_REPLACEMENT);
            if (uiStore.getValueForUIObject(this.dialogId, `LIST_FIXED_COLUMNS_${columnName}`) ?? col.initialDisplay === 'PINNED') {
                fixedLeftColumns.push(columnName);
            }
            else {
                normalColumns.push(columnName);
            }
        });
        return [
            ...fixedLeftColumns,
            ...normalColumns,
        ];
    }

    getColumnData = (columns, isDoubleClickEnabled ) => {
       const {
           sortValues,
           isSearchFormOpen,
           isColumnFiltered,
           uiStore } = this.props;

        const columnData = columns.map((c) => {
            const {
                heading,
                initialDisplay,
                propertyName,
                spacer,
            } = c;
            if (spacer) {
                return {
                    name: propertyName,
                    spacer: true,
                    title: '',
                };
            }
            const name = propertyName.replace('.', DOT_REPLACEMENT);
            let sorted = {};
            let isAscending = false;
            let priority;
            if (!isSearchFormOpen && sortValues) {
                sorted = sortValues.find((sortedColumn) => (sortedColumn.name === c.propertyName.replace(DOT_REPLACEMENT, '.')));
                isAscending = sorted && sorted.isAscending;
                priority = sorted && sorted.priority;
            }
            return {
                // Handle property names with dots
                name,
                title: heading,
                fixedColumn: uiStore.getValueForUIObject(this.dialogId, `LIST_FIXED_COLUMNS_${name}`) ?? initialDisplay === 'PINNED',
                hidden: uiStore.getValueForUIObject(this.dialogId, `${LIST_HIDDEN_COLUMNS}_${name}`) ?? initialDisplay === 'HIDDEN',
                isFiltered: isColumnFiltered(name),
                isAscending,
                isPinned: initialDisplay === 'PINNED',
                priority,
            };
        });
        if (!isDoubleClickEnabled) columnData.unshift(SelectionColumn);
        return columnData;
    };

    getRowData = (rows, isDoubleClickEnabled) => (
        rows.map((record) => {
            // Get record properties
            const {
                properties,
            } = record;

            // Initialize row property:value container
            const row = {};

            if (!isDoubleClickEnabled) {
                row[SelectionColumn.name] = SelectionColumn.title;
            }

            // For each property
            properties.forEach((property) => {
                // Get property name:value set
                const {
                    name,
                    value,
                } = property;

                // Add property to row (handle property names with dots)
                row[name.replace('.', DOT_REPLACEMENT)] = value;
            });

            return row;
        })
    );

    getSelectedRowIndexes = () => {
        const {
            records,
            uiStore,
            dialogStore } = this.props;

        const selectedRecordIds = listHelper.getSelectedRecords(uiStore, dialogStore);
        return Object.keys(selectedRecordIds).map((selectedRecordId) => (
            records.findIndex((record) => (record.id === selectedRecordId))
        ));
    };

    handleFetchCellPropertyProps = (rowId, propertyName) => {
        const {
            asyncDataCallback,
            columns,
            recordDef,
            records } = this.props;

        const record = records[rowId];
        const property = record && record.propAtName(propertyName);

        // Apply image annotation styles
        if (property && record) {
            const propDef = recordDef.propDefAtName(propertyName);
            const viewDef = columns.find((col) => (col.propertyName === propertyName));
            const isImageWithURL = viewDef.displayMediaInline && propDef.isURLType;
            const isTextWithImage = !!(property.imageName || record.imageName) || isImageWithURL;
            const imageUrl = isImageWithURL ? property.value : (property.imageName || record.imageName);

            const childProps = {
                key: `${record.id}-${propertyName}-tabelcell`,
                rowId,
                columnName: propertyName,
                tipText: property.tipText || record.tipText,
                textString: property.isPlacementCenter || record.isPlacementCenter || isImageWithURL ? '' : listHelper.formatGridData(record, property, propDef, ''),
                imageUrl: isTextWithImage ? imageUrl : '',
                isPlacementCenter: property.isPlacementCenter,
                isPlacementRight: property.isPlacementRight,
                isPlacementLeft: property.isPlacementLeft,
                isPlacementStretchUnder: property.isPlacementStretchUnder,
                isPlacementUnder: property.isPlacementUnder,
                isNumericType: propDef.isNumericType || propDef.isDecimalType,
                isTextWithImage,
                isLargeProperty: (propDef.isLargePropertyType && asyncDataCallback !== null && property.imageName === null) || false,
                onClick: this.handleRowSelectionChange,
                fetchLargeProperty: this.handleFetchLargeProperty,
                fetchAnnotationStyles: this.handleFetchAnnotationStyles,
            };
            return childProps;
        }

        return {
            rowId,
            columnName: propertyName,
            onClick: this.handleRowSelectionChange,
        };
    }

    handleFetchAnnotationStyles = (propertyName, rowId, selected) => {
        const { records } = this.props;

        const record = records[rowId];
        const property = record && record.propAtName(propertyName);
        return selected ? undefined : annotationHelper.getAsStyle(record, property, {});
    }

    handleFetchLargeProperty = (propertyName, rowId) => {
        const {
            asyncDataCallback,
            recordDef,
            records} = this.props;

        const record = records[rowId];
        const property = record && record.propAtName(propertyName);
        const propDef = recordDef.propDefAtName(propertyName);
        const { columns, viewType } = this.props;

        return this.dialogComponentBuilder
            .setAsyncDataCallback(() => asyncDataCallback(propertyName, record.id))
            .setViewType(viewType)
            .setViewDef(columns.find((col) => (col.propertyName === propertyName)))
            .setProperty(property)
            .setPropDef(propDef)
            .build();
    }

    handleColumnFrozen = (columnName, columnLocked) => {
        const { uiStore } = this.props;
        const columnOrder = uiStore.getValueForUIObject(this.dialogId, LIST_COLUMN_ORDER) || this.columnNamesOriginalOrder;
        const fixedLeftColumns = uiStore.getValueForUIObject(this.dialogId, LIST_FIXED_COLUMNS) || [];

        // Initialize newly fixed left columns array
        let updatedFixedLeftColumns = [];

        // Pull out column
        let updatedColumnOrder = columnOrder.filter((c) => (c !== columnName));

        // If column is locked
        if (columnLocked) {
            // Pull column from locked columns list
            updatedFixedLeftColumns = fixedLeftColumns.filter((c) => (c !== columnName));

            // Reorder the columns, injecting frozen column in appropriate location
            updatedColumnOrder = [
                ...updatedFixedLeftColumns,
                ...this.columnNamesOriginalOrder.filter((c) => !updatedFixedLeftColumns.includes(c)),
            ];
        }
        else {
            // Add column to locked columns list
            updatedFixedLeftColumns = fixedLeftColumns.concat(columnName);

            // Reorder the columns, injecting frozen column in appropriate location
            updatedColumnOrder.splice(fixedLeftColumns.length, 0, columnName);
        }

        uiStore.setValueForUIObject(this.dialogId, `LIST_FIXED_COLUMNS_${columnName}`, !columnLocked);
        uiStore.setValueForUIObject(this.dialogId, LIST_COLUMN_ORDER, updatedColumnOrder);
        uiStore.setValueForUIObject(this.dialogId, LIST_FIXED_COLUMNS, updatedFixedLeftColumns);
    };

    handleColumnVisibility = (event) => {
        const anchorPosition = {
            left: event.clientX,
            top: event.clientY,
        };
        this.columnChooserRef.current.show(anchorPosition);
    }

    handleHiddenColumsChange = (columnName, hidden) => {
        const { uiStore } = this.props;
        uiStore.setValueForUIObject(this.dialogId, `${LIST_HIDDEN_COLUMNS}_${columnName}`, hidden);
    }

    handleColumnsReordered = (columnsNamesInOrder) => {
        const { uiStore } = this.props;
        uiStore.setValueForUIObject(this.dialogId, LIST_COLUMN_ORDER, columnsNamesInOrder);
        this.columnNamesOriginalOrder = [ ...columnsNamesInOrder ];
    };

    handleOnRowsLoaded = (row) => {
        const { uiStore, dialogStore } = this.props;
        const { records } = dialogStore;
        if ((row + 1) === records.length) {
            uiStore.setValueForUIObject(this.dialogId, LIST_PAGE_OVERRIDE_INITIAL_SIZE, records.length);
        }
    }

    handleLoadMoreData = () => {
        const { onRequestMore, isPagingEnabled } = this.props;
        if (onRequestMore && !this.initializingLoad && !isPagingEnabled) {
            onRequestMore();
        }
    };
    handleContextMenu = (event, rowId) => {
        const { onSelectItem, isRecordSelected } = this.props;
        const { clientX, clientY } = event;
        if (onSelectItem) {
            const { records } = this.props;
            const record = records[rowId];
            if (record) {
                const { id } = record;
                if (isRecordSelected(id)) {
                    onSelectItem(id, true, 'DESKTOP');
                }
                else {
                    onSelectItem(id, false, 'DESKTOP');
                }
                this.contextMenuRef.current.show({ top: clientY, left: clientX });
            }
        }
    }

    handleRowSelectionChange = (newSelectionIndex, event, propertyName) => {
        const {
            onChooseItem,
            onSelectItem,
            onSelectMultipleItems,
            doubleClickForDefaultAction,
            records } = this.props;

        const { type, target } = event;
        const { type: targetType } = target;
        if (event.shiftKey) {
            onSelectMultipleItems(newSelectionIndex);
        }
        else if (event.ctrlKey || event.metaKey) {
            onChooseItem(records[newSelectionIndex].id, true, 'DESKTOP');
        }
        else if (type === 'click' && doubleClickForDefaultAction) {
            onSelectItem(records[newSelectionIndex].id, false, 'DESKTOP');
        }
        else {
            const fireOnChoseItem = doubleClickForDefaultAction && type === 'dblclick' || !doubleClickForDefaultAction && (type === 'click' && targetType !== 'checkbox');
            const modifiers = event.altKey ? { [constants.transitionModifiers.OPEN_IN_TAB]: true } : undefined;

            if (fireOnChoseItem) {
                onChooseItem(records[newSelectionIndex].id, false, 'DESKTOP', modifiers, propertyName);
            }
            else {
                onChooseItem(records[newSelectionIndex].id, true, 'DESKTOP');
            }
        }
    }
}

export default RWGridTable;
