import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { constants } from 'cv-react-core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { XaltBox, XaltTextLabel, XaltToolTip } from 'xalt-react-atoms';
import Image from '../../base/Image';
import getStyles from './LauncherDrawer.styles';
import WorkbenchMenu from './WorkbenchMenu';

class LaunchersDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.boxRef = React.createRef();
    }

    render() {
        const {
            activeLauncher,
            launchers,
            navigator,
            onOpenLauncher,
        } = this.props;
        const styles = getStyles();

        const { open } = this.state;

        let drawerStyles = styles.drawer;

        if (open) {
            drawerStyles = { ...drawerStyles, ...styles.expanded };
        }

        return (
            <div style={ styles.wrapper }>
                <div style={ styles.fixedHeader }>
                    <WorkbenchMenu navigator={ navigator } />
                </div>
                <Drawer
                    anchor="left"
                    variant="permanent"
                    PaperProps={ { style: drawerStyles } }
                    open={ open }>
                    <List
                        style={ styles.listContainer }>

                        { launchers.map((launcher) => {
                        const selected = activeLauncher === launcher.id;
                        const spanProps = selected ? { ref: this.boxRef } : {};

                        return (
                            <ListItem
                                style={ styles.launcherContainer }
                                onClick={ (nativeEvent) => {
                                    // When we navigate to other launcher, we have to clear all the exiting dialog breadcrumbs.
                                    const modifiers = {
                                        [constants.ui.CLEAR_DIALOG_BREAD_CRUMBS]: true,
                                        [constants.transitionModifiers.OPEN_IN_TAB]: !!nativeEvent.altKey,
                                    };
                                    // Don't allow click when launcher is already selected.
                                    if (!selected) {
                                        onOpenLauncher(launcher, modifiers);
                                        this.setState({open: false });
                                    }
                                } }
                                key={ launcher.id }
                                { ...spanProps }>
                                <XaltToolTip tipText={ launcher.name }>
                                    <XaltBox
                                        hoverStyle={ styles.listHover }
                                        style={ selected ? { ...styles.listItemContainer, ...styles.listItemSelected } : styles.listItemContainer }>
                                        <Image
                                            contextStyles={ {
                                            container: styles.imageContainer,
                                            image: selected ? styles.imageSelected : styles.image,
                                        } }
                                            imageSrc={ launcher.iconBase }
                                            showLoadingIndicator={ false } />
                                        { open &&
                                        <XaltTextLabel style={ styles.launcherText }>
                                            { launcher.name }
                                        </XaltTextLabel>
                                    }
                                    </XaltBox>
                                </XaltToolTip>
                            </ListItem>
                        );
                    }) }
                    </List>
                </Drawer>
                <div
                    style={ open ? { ...styles.expandButton, ...styles.expandedButton } : styles.expandButton }
                    onClick={ () => this.setState({open: !open } ) }>
                    <span style={ styles.keyline } />
                    { open ? <ChevronLeftIcon /> : <ChevronRightIcon /> }
                </div>

            </div>
        );
    }

    componentDidUpdate(prevPops) {
        const { activeLauncher } = this.props;
        if (activeLauncher !== prevPops.activeLauncher) {
            this.scrollIntoViewIfNeeded();
        }
    }

    componentDidMount() {
        this.scrollIntoViewIfNeeded();
    }

    scrollIntoViewIfNeeded = () => {
        if (this.boxRef?.current && !this.isElementInViewport()) {
            // Scroll into view only when element is not visible in viewport.
            this.boxRef.current.scrollIntoView({
                block: 'center',
            });
        }
    }

    /**
     * Identifies whether a given element is visible on the screen.
     * @returns true/false
     */
    isElementInViewport = () => {
        if (!this.boxRef || !this.boxRef.current) return false;
        const { top } = this.boxRef.current.getBoundingClientRect();
        return top >= 0 && top <= window.innerHeight;
    }
};

LaunchersDrawer.propTypes = {
    activeLauncher: PropTypes.string,
    launchers: PropTypes.array,
    navigator: PropTypes.object,
    onOpenLauncher: PropTypes.func,
};

LaunchersDrawer.defaultProps = {
    onOpenLauncher: () => {},
};

export default LaunchersDrawer;
