import React, { PureComponent } from 'react';
import { constants, rootStore, uiHelper, serviceFactory } from 'cv-react-core';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Modal from '../base/Modal';
import TextLabel from '../base/TextLabel';
import IconButton from '../base/IconButton';
import Image from '../base/Image';
import getStyles from './styles/AboutModal.styles';

const { ui } = constants;
const { APPLICATION_UI_ID, ABOUT_MODAL_IS_OPEN } = ui;

@observer
class AboutModal extends PureComponent {
    static propTypes = {
        contextStyles: PropTypes.object,
        uiStore: PropTypes.object,
    };

    render() {
        const {
          contextStyles,
          uiStore,
        } = this.props;

        const isModalOpen = uiStore.getValueForUIObject(APPLICATION_UI_ID, ABOUT_MODAL_IS_OPEN);
        const { sessionStore } = rootStore;
        const clientInformation = uiHelper.getClientInformation(sessionStore);
        const { lang } = serviceFactory;
        const { session: { tenantProperties } } = sessionStore;
        const { aboutPageLogoURL } = tenantProperties;
        const {
            modal,
            container,
            closeIconButton,
            closeButtonContainer,
            closeIcon,
            aboutTitle,
            menuImage,
            versionContainer,
            versionValue,
        } = getStyles(contextStyles);
        return (
            <Modal
                onClose={ this.handleClose }
                isTransparentOverlay={ false }
                contextStyles={ { modal } }
                isModalOpen={ !!isModalOpen }>
                <div
                    className="p-about-page"
                    style={ container }>
                    <div style={ closeButtonContainer }>
                        <IconButton
                            contextStyles={ {
                                button: closeIconButton,
                                icon: closeIcon,
                            } }
                            iconName="cancel_circle"
                            onClick={ this.handleClose }
                            title="Close" />
                    </div>
                    <div style={ versionContainer }>
                        <Image
                            contextStyles={ {
                                image: menuImage,
                            } }
                            imageSrc={ aboutPageLogoURL } />
                        <TextLabel
                            contextStyles={ {
                                text: aboutTitle,
                            } }>
                            About
                        </TextLabel>
                    </div>
                    <div style={ versionContainer }>
                        {
                            clientInformation.map((version) => (
                                version.value &&
                                <div
                                    key={ version.name }
                                    style={ versionContainer }>
                                    <TextLabel
                                        contextStyles={ {
                                            text: versionValue,
                                        } }>
                                        { `${ version.name }:` }
                                    </TextLabel>
                                    <TextLabel
                                        contextStyles={ {
                                            text: versionValue,
                                        } }>
                                        { version.value }
                                    </TextLabel>
                                </div>
                            ))
                        }
                        <TextLabel
                            contextStyles={ {
                                text: versionValue,
                            } }>
                            { lang.applicationInfo.copyright }
                        </TextLabel>
                    </div>
                </div>
            </Modal>
        );
    }

    handleClose = () => {
        const { uiStore } = this.props;
        uiStore.removeValueForUIObject(APPLICATION_UI_ID, ABOUT_MODAL_IS_OPEN);
    }
}

export default AboutModal;