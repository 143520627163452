import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { rootStore, engineConstants, constants } from 'cv-react-core';
import { CvScrollView } from 'cv-library-react-web';

import getBrowserSupport, { supportedBrowsers } from '../utilities/getBrowserSupport';
import ListActivityIndicator from '../components/base/ListActivityIndicator/ListActivityIndicator';
import TextLabel from '../components/base/TextLabel';
import CvContextMenu from '../components/menu/CvContextMenu';
import MenuItem from '../components/base/MenuItem';
import lang from '../nls/i18n';
import Record from '../components/base/GMLRecord/GMLRecord';

const { name: BrowserName } = getBrowserSupport();

export const propTypes = {
    style: PropTypes.object,
    xStyle: PropTypes.object,
    hasMoreRecords: PropTypes.bool,
    showVerticalScrollBar: PropTypes.bool,
    showHorizontalScrollBar: PropTypes.bool,
    refreshInProgress: PropTypes.bool,
    onChooseItem: PropTypes.func,
    onSelectItem: PropTypes.func,
    onMenuAction: PropTypes.func,
    onRenderItem: PropTypes.func,
    isRecordSelected: PropTypes.func,
    onSelectMultipleItems: PropTypes.func,
    records: PropTypes.array,
    queryInProgress: PropTypes.bool,
    availableMenuItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.string,
        menuText: PropTypes.string,
    })),
    keyExtractor: PropTypes.func.isRequired,
    onRequestMore: PropTypes.func,
    xMenu: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
    })),
    doubleClickForDefaultAction: PropTypes.bool,
    recordsSelectionState: PropTypes.object,
};

class RWDefaultList extends Component {
    static propTypes = propTypes;

    static defaultProps = {
        onChooseItem: () => Promise.resolve(),
        onSelectItem: () => Promise.resolve(),
        onMenuAction: () => Promise.resolve(),
        onRequestMore: () => Promise.resolve(),
        onSelectMultipleItems: () => Promise.resolve(),
        isRecordSelected: () => Promise.resolve(),
        availableMenuItems: [],
        showHorizontalScrollBar: false,
        showVerticalScrollBar: false,
        doubleClickForDefaultAction: false,
    };

    constructor(props) {
        super(props);
        this.contextMenuRef = React.createRef();
        this.scrollViewRef = React.createRef();
        this.renderData = this.renderData.bind(this);
        this.handlePress = this.handlePress.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);

        // TODO: This section needs refactoring...
        const { themeStore } = rootStore;
        this.theme = themeStore.getSanitizedTheme();
        const {
            xStyle,
        } = props;
        const resolve = (name, theme) => {
            // If property exists on xStyle use it even if it's null
            if (xStyle && (xStyle[name] !== undefined)) {
                return xStyle[name];
            }
            return (theme && theme[name]) || undefined;
        };
        this.menuItems = [];
        this.listItemColors = {
            listUnderlayColor: resolve('listUnderlayColor', this.theme),
            listSelectedUnderlayColor: resolve('listSelectedUnderlayColor', this.theme),
            listTapUnderlayColor: resolve('listTapUnderlayColor', this.theme),
            listAlternatingColors: resolve('listAlternatingColors', this.theme.colors),
        };
        this.listEmptyLabelColor = { text: { color: resolve('listEmptyLabelColor', this.theme) }};
        this.scrollStyleForChrome = BrowserName === supportedBrowsers.chrome.name ? { overflow: 'overlay' } : { };
    }

    render() {
        const {
            hasMoreRecords,
            records,
            onRequestMore,
            queryInProgress,
            refreshInProgress,
            style,
            showHorizontalScrollBar,
            showVerticalScrollBar,
        } = this.props;
        const isLoading = (hasMoreRecords && !records.length) || refreshInProgress || queryInProgress;


        if (records.length === 0 && !isLoading) {
            return (
                <div style={ style }>
                    <TextLabel contextStyles={ this.listEmptyLabelColor }>
                        { lang.list.noRecords }
                    </TextLabel>
                </div>
            );
        }
        const scrollViewStyle = {
            column: { ...this.scrollStyleForChrome },
        };

        return (
            <>
                <CvScrollView
                    contextStyles={ scrollViewStyle }
                    isLoading={ isLoading }
                    loadingDataIndicator={ this.loadingIndicator() }
                    onRequestMore={ onRequestMore }
                    hasMoreData={ hasMoreRecords }
                    showHorizontalScrollBar={ showHorizontalScrollBar }
                    showVerticalScrollBar={ showVerticalScrollBar }>
                    { this.renderData() }
                </CvScrollView>
                <CvContextMenu
                    ref={ this.contextMenuRef }>
                    { this.menuItems }
                </CvContextMenu>
            </>
        );
    }

    componentDidMount() {
        // TODO: This section needs refactoring...
        // Build menu items.
        const {
            availableMenuItems,
            xMenu,
            onMenuAction,
        } = this.props;

        const extendedMenu = xMenu.find((f) => (f.id === engineConstants.action.clientActions.copyToClipboard));
        this.extendedMenuItems = [ ...availableMenuItems ];

        if (extendedMenu) {
            this.extendedMenuItems.push({
                ...extendedMenu,
                menuText: lang.list.copyToClipboard,
            });
        }
        this.menuItems = this.extendedMenuItems.map((item) => (
            <MenuItem
                key={ `${item.id}${item.menuText}` }
                id={ item.id }
                onClick={ () => {
                    if (onMenuAction) {
                        onMenuAction(item);
                    }
                } }
                text={ item.menuText } />
        ));
    }

    renderData() {
        const {
            records,
            onRenderItem,
            keyExtractor,
            recordsSelectionState,
        } = this.props;

        const baseRecordProps = {
            onRenderItem,
            keyExtractor,
            recordsSelectionState,
            contextStyles: this.listItemColors,
            onClick: this.handlePress,
            onDoubleClick: this.handlePress,
            onContextMenu: this.handleContextMenu,
        };
        return (
            records.map((record, index) => {
                const recordProps = {
                    ...baseRecordProps,
                    key: keyExtractor(record),
                    record,
                    index,
                };
                return React.createElement(Record, { ...recordProps });
            })
        );
    }

    // eslint-disable-next-line class-methods-use-this
    loadingIndicator() {
        return <ListActivityIndicator />;
    }

    get contextMenu() {
        return this.contextMenuRef;
    }

    handlePress(event) {
        event.preventDefault();
        event.stopPropagation();
        const {
            onChooseItem,
            onSelectItem,
            xStyle,
            doubleClickForDefaultAction,
            records,
            onSelectMultipleItems,
        } = this.props;

        const disabled = (xStyle && xStyle.disableItemTaps) || false;
        if (disabled) return;

        const { currentTarget, type } = event;
        const { id } = currentTarget;

        if (event.shiftKey) {
            const curentIndexSelected = records.findIndex((record) => record.id === id);
            onSelectMultipleItems(curentIndexSelected);
        }
        else if (event.ctrlKey || event.metaKey) {
            onChooseItem(id, true, 'DESKTOP');
        }
        else if (type === 'click' && doubleClickForDefaultAction) {
            onSelectItem(id, false, 'DESKTOP');
        }
        else {
            const fireOnChoseItem = doubleClickForDefaultAction && type === 'dblclick' || !doubleClickForDefaultAction && type === 'click';
            const modifiers = event.altKey ? { [constants.transitionModifiers.OPEN_IN_TAB]: true } : undefined;

            if (fireOnChoseItem) {
                onChooseItem(id, false, 'DESKTOP', modifiers);
            }
            else {
                onChooseItem(id, true, 'DESKTOP');
            }
        }
    }

    handleContextMenu(event) {
        const {
            onSelectItem,
            isRecordSelected,
        } = this.props;

        const {
            currentTarget,
            clientX,
            clientY,
        } = event;
        const { id } = currentTarget;


        if (onSelectItem && id) {
            if (isRecordSelected(id)) {
                onSelectItem(id, true, 'DESKTOP');
            }
            else {
                onSelectItem(id, false, 'DESKTOP');
            }
            this.contextMenu.current.show({ top: clientY, left: clientX });
        }
    }
}
export default RWDefaultList;
