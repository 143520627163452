import React from 'react';
import * as PropTypes from 'prop-types';

import { Animated } from 'react-web-animation';

import { utilities } from 'cv-react-core';
import { XaltBox } from 'xalt-react-atoms';
import { Resizable } from 'cv-library-react-web';
import RWSaltComponent from './RWSaltComponent';

export default class RWBox extends RWSaltComponent {
    static propTypes = {
        // allows styling of the 'exterior' scroll pane itself
        // some values don't make sense here and are not allowed (i.e. align-items)
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),

        xStyle: PropTypes.object,

        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        scroll: PropTypes.string,
        animate: PropTypes.shape({
            effect: PropTypes.string.isRequired,
            duration: PropTypes.number,
            easing: PropTypes.string,
            iterations: PropTypes.number,
            delay: PropTypes.number,
        }),
        onResizeStop: PropTypes.func,
        resizable: PropTypes.bool,
        direction: PropTypes.string,
        initialWidth: PropTypes.string,
        initialHeight: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.myBoxRef = React.createRef();
    }

    render() {
        const {
            style,
            xStyle,
            children,
            scroll,
            animate,
            onResizeStop,
            resizable,
            direction,
            initialWidth,
            initialHeight,
        } = this.props;

        const { focus, hover } = xStyle || {};
        let overflowX = {};
        let overflowY = {};
        if (scroll && (scroll === 'horizontal' || scroll === 'vertical')) {
            const overflowXFromStyle = style.overflow || style.overflowX || 'hidden';
            const overflowYFromStyle = style.overflow || style.overflowY || 'hidden';
            overflowX = { overflowX: `${scroll === 'horizontal' ? 'auto' : overflowXFromStyle }` };
            overflowY = { overflowY: `${scroll === 'vertical' ? 'auto' : overflowYFromStyle }`};
        }

        const combinedStyles = RWSaltComponent.extractCombinedStyles(style);
        const boxProps = {
            style: { display: 'flex', ...combinedStyles, ...overflowX, ...overflowY },
            focusStyle: focus,
            hoverStyle: hover };

        if ( animate ) {
            const {
                effect,
                duration,
                easing,
                iterations,
                delay } = animate;
            const animationProps = { duration, easing, iterations, delay };
            const effectKeyFrames = utilities.animationHelper.extractWebAnimationEffect(effect);
            return (
                <Animated.div
                    keyframes={ this.getKeyFrames(effectKeyFrames) }
                    timing={ this.getTiming(animationProps) }>
                    <XaltBox { ...boxProps }>
                        { children }
                    </XaltBox>
                </Animated.div>
            );
        }

        if (resizable) {
            const {
                width,
                height,
            } = style;
            boxProps.style = {
                ...boxProps.style,
                width: '100%',
                height: '100%',
            };
            return (
            initialWidth ?
                <Resizable
                    className="left-column-content"
                    defaultSize={ {
                        width: initialWidth || width || '100%',
                        height: initialHeight || height || '100%',
                    } }
                    direction={ direction || 'both' }
                    key="left-column-content"
                    onResizeStop={ onResizeStop }>
                    <XaltBox { ...boxProps }>
                        { children }
                    </XaltBox>
                </Resizable>
                :
                <div
                    style={ {...style} }
                    ref={ this.myBoxRef } />
            );
        }

        return (
            <XaltBox { ...boxProps }>
                { children }
            </XaltBox>
        );
    }

    componentDidMount() {
        const { resizable, onResizeStop } = this.props;
        if (resizable && this.myBoxRef.current) {
            onResizeStop(null, null, { style: { width: `${this.myBoxRef.current.offsetWidth}px` }});
        }
    }

    getTiming = (animationprops) => animationprops;

    getKeyFrames = (animationFrames) => animationFrames ;
}
