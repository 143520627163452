import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { rootStore, constants, serviceFactory } from 'cv-react-core';
import { Log } from 'cv-dialog-sdk';

import Navigator from './Navigator';
import routeNames from './routeNames';
import AuthLayout from '../components/layouts/AuthNavigation/AuthNavigation';

const {
    LOGIN,
    SETTINGS,
} = routeNames;

const allowedRoutes = [
    LOGIN,
    SETTINGS,
];

const targets = {
    [LOGIN]: {
        route: LOGIN,
        name: 'Login',
    },
    [SETTINGS]: {
        route: SETTINGS,
        name: 'Settings',
    },
};

const INVALID_TENANT_URL = 'INVALID_TENANT_URL';

class AuthRoute extends Component {
    render() {
        const navigator = new Navigator(this.props);
        const { route, tenantId } = navigator.match.params;
        const { settingsStore } = rootStore;
        const invalidUrlTenant = settingsStore.getValue(INVALID_TENANT_URL);
        const redirectLocation = invalidUrlTenant ? SETTINGS : LOGIN;
        return allowedRoutes.includes(route) && !invalidUrlTenant ?
            (<AuthLayout
                targets={ targets }
                defaultRouteName={ route }
                navigator={ navigator }
                testID="auth-layout" />) :
            (<Redirect to={ { pathname: `/${tenantId || '#'}/${redirectLocation}` } } />);
    }

    componentDidMount() {
        const {
            uiStore,
            settingsStore,
        } = rootStore;
        uiStore.clearAll();
        // below code is to show error to the use if there is a invalid session in the deep link url
        // If we set in the UI store in app.js, it is getting cleared in above uistore.clear call. So to persist
        // saving the error in setting store and now adding for display
        const deepLinkingError = settingsStore.getValue('DEEP_LINKING_ERROR');
        if (deepLinkingError) {
            const newError = {
                type: 'generic',
                msg: deepLinkingError,
            };
            uiStore.addErrorForUIObject(constants.ui.APPLICATION_UI_ID, newError);
            settingsStore.setValue('DEEP_LINKING_ERROR', null);
        }

        // check we have a valid tenant id if one provided in route.
        // This value is set only when the application mounts in the componentDidMount method inside the App.js
        const invalidUrlTenant = settingsStore.getValue(INVALID_TENANT_URL);
        if (invalidUrlTenant) {
            settingsStore.setValue(INVALID_TENANT_URL, false);
            const invalidTenantId = settingsStore.getValue('INVALID_TENANT_ID_USED');
            const { lang: { login } } = serviceFactory;
            const errorMessage = `${login.invalidTenantUrlPath} ${invalidTenantId}; ${login.lastRecognizedTenant} ${login.bookmarkLoginPage}`;
            serviceFactory.notify.showError(errorMessage);
            Log.warn(errorMessage);
        }
    }
};

export default AuthRoute;
