import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { constants, dialogComponentFactory, rootStore, uiHelper, utilities } from 'cv-react-core';

import pageController from 'cv-react-core/src/controllers/pageController';
import Route from './Route';
import lang from '../nls/i18n';
import FormToolBar from '../components/panels/FormToolBar/FormToolBar';
import ConfirmModal from '../components/modal/ConfirmModal';
import DialogBreadcrumbs from '../components/base/Breadcrumbs/DialogBreadcrumbs';
import LoadingIndicator from '../components/modal/LoadingIndicator';
import ErrorRouteModal from '../components/modal/ErrorRouteModal';

const {
    error,
    ui,
} = constants;
const { MODAL_DIALOG_ID, MODAL_TRANSITION } = ui;
const {
    errorTypes,
} = error;
const {
    hard,
} = errorTypes;

@observer
class DialogRoute extends Route {
    static propTypes = {
        dialogId: PropTypes.string,
    };

    // Debug Proxy Example
    // @DebugMethod({ logLevel: LogLevel.TRACE, logConfig: Log.lime, loggerArgs: [ 'route:nav' ] })
    render() {
        const {
            dialogId,
        } = this.props;
        const {
            sessionStore,
            uiStore,
        } = rootStore;

        const dialogStore = sessionStore.getOpenDialogStore(dialogId);
        const loadingProps = {
            objectId: dialogId,
            uiStore,
        };
        if (dialogStore) {
            const title = dialogStore.dialog.description;
            // Set browser tab name to the workbench name.
            // Doing this on each route for consistency for now.
            document.title = title;
            const formProps = {
                dialogId,
                uiStore,
                title,
                onMenuAction: (menuEvent) => this.handleMenuAction(dialogStore, menuEvent),
                onGroupAction: (menuEvent) => this.handleMenuAction(dialogStore, menuEvent),
                onOpenView: (viewDescriptor) => this.handleOpenView(dialogStore, viewDescriptor),
            };
            return (
                <Fragment>
                    { React.createElement(LoadingIndicator, { ...loadingProps }) }
                    { React.createElement(ErrorRouteModal, { ...loadingProps }) }
                    { React.createElement(DialogBreadcrumbs, { dialogStore }) }
                    <FormToolBar { ...formProps } />
                    { this.renderMain(dialogStore) }
                    { <ConfirmModal
                        uiStore={ uiStore }
                        dialogId={ dialogId } /> }
                </Fragment>
            );
        }

        return React.createElement(LoadingIndicator, { ...loadingProps });
    }

    // Note: the router calls this on every page transition
    componentDidMount() {
        const {
            dialogId,
        } = this.props;
        const {
            sessionStore,
            uiStore,
        } = rootStore;
        const dialogStore = sessionStore.getOpenDialogStore(dialogId);
        if (!dialogStore) {
            this.openDialogStore(dialogId);
        }
        document.addEventListener('keydown', this.handleOnSaltEditorKeys);
        // Passing correct transition method to be consumed by Dialog Modal in the scenario where current dialog is destroyed and going back to previous Dialog in history.
        // ref bug: https://dev.azure.com/HexagonXalt/Xalt%20Mobility/_workitems/edit/30061
        uiStore.setValueForUIObject(MODAL_DIALOG_ID, MODAL_TRANSITION, {
            onTransition: this.handleTransition,
        });
    }

    handleOnSaltEditorKeys = (event) => {
        const { dialogId } = this.props;
        const { sessionStore, uiStore } = rootStore;
        // ctrl + alt + shift + s - show the form dialog store salt editor
        if (event.ctrlKey && event.altKey && event.shiftKey && event.keyCode === 83) {
            const dialogStore = sessionStore.getOpenDialogStore(dialogId);
            if (dialogStore) pageController.performShowSaltEditor({ dialogStore, uiStore, onError: this.handleError });
       }
    }

    // Note: the router calls this everytime we move away from this page

    // ALERT - We are currently removing DialogStore from the cache when tranistioning to a new page,
    // but we are not destroying (on the server) them so that we can continue to retrieve them when
    //  moving both forward and backward.
    // If we decide we need to 'keep' previous DialogStore's in the cache when moving forward, so that they
    // are available in the cache when moving backward, we'll need to figure out how to catch a 'pop' event
    //  for this route from browser's history (i.e. a back transition)
    // UPDATE: This clean up on 'goBack' has now been implemeneted in cv-react-native through it's Navigation object.
    // It can be ported here if memory is an issue.

    componentWillUnmount() {
        const {
            dialogId,
        } = this.props;
        const { sessionStore } = rootStore;
        /*
        const { uiStore } = rootStore;
        uiStore.setValueForUIObject(dialogId, constants.ui.DIALOG_VIEW_VISIBLE, false);
        */
        sessionStore.removeOpenDialogStore(dialogId);
        document.removeEventListener('keydown', this.handleOnSaltEditorKeys);
    }

    renderMain = (dialogStore) => {
        const {
            uiStore,
        } = rootStore;
        const params = {
            dialogStore,
            uiStore,
            onTransition: this.handleTransition,
            onError: this.handleError,
        };

        try {
            const component = dialogComponentFactory.getDialogPageComponent(dialogStore.dialog);
            return (
                <Fragment>
                    { React.createElement(component, params) }
                </Fragment>
            );
        }
        catch (err) {
            const title = lang.dialog.errors.errorOpenDialogTitle;
            return this.handleError({
                title,
                err,
                type: hard,
            });
        }
    };

    openDialogStore = (dialogId) => {
        const { sessionStore } = rootStore;
        return sessionStore.getOrOpenDialogStore(dialogId).then(() => {
                this.setScrollPosition(dialogId);
            })
            .catch((err) => {
                const title = lang.dialog.errors.errorOpenDialogTitle;
                this.handleError({
                    title,
                    err,
                    type: hard,
                });
            });
    };

    handleMenuAction = (dialogStore, menuEvent) => {
        const {
            uiStore,
        } = rootStore;
        const targetDialogStore = uiHelper.findDialogStoreWithMenu(dialogStore, menuEvent);
        const { id, modifiers } = menuEvent;
        pageController.performActionWithConfirmation({
            actionId: id,
            selectedArray: utilities.listHelper.getSelectedAsArray(uiStore, targetDialogStore),
            dialogStore: targetDialogStore,
            uiStore,
            onTransition: this.handleTransition,
            onError: this.handleError,
            modifiers,
        });
    };

    handleOpenView = (dialogStore, viewDescriptor) => {
        const {
            uiStore,
        } = rootStore;
        return pageController.performOpenViewWithConfirmation({
            viewDescriptor,
            dialogStore,
            uiStore,
            onTransition: this.handleTransition,
            onError: this.handleError,
        });
    }

    setScrollPosition = (dialogId) => {
        // logic to check for scroll positon of the section ( if available in uistore already ) and set it
        const { uiStore } = rootStore;
        const scrollableElement = document.querySelector(`div[data-scroll-identifier=${constants.ui.SCROLL_CONTAINER}]`);
        const SCROLLPOSITION = uiStore.getValueForUIObject(dialogId, constants.ui.SCROLL_POSITION);

        if (scrollableElement && SCROLLPOSITION) {
            scrollableElement.scrollTop = SCROLLPOSITION;
        }
    }

    isDialogDestroyed = (dialogId) => {
        const { sessionStore } = rootStore;
        // If the dialog we are trying to reopen is destroyed, we need to go back.
        const dialogStore = sessionStore.getOpenDialogStore(dialogId);
        if (!dialogStore) return true;

        const { dialog } = dialogStore;
        return dialog && dialog.isDestroyed;
    }
}

export default DialogRoute;
