import React, { PureComponent, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { rootStore, serviceFactory } from 'cv-react-core';
import { Box, HolyGrailLayout } from 'cv-library-react-web';
import IconButton from '@material-ui/core/IconButton';
import RateReviewIcon from '@material-ui/icons/RateReview';

import AppBar from '../../base/AppBar';
import AppHeader from '../../base/AppHeader/AppHeader';
import AppFooter from '../../base/AppFooter/AppFooter';
import Image from '../../base/Image';

import routeNames from '../../../routing/routeNames';
import { getRouteComponent } from '../../../routing/routeComponents';
import getStyles from './AppNavigation.styles';
import Navigator from '../../../routing/Navigator';
import NotificationsRoute from '../../../routing/NotificationsRoute';
import DialogModal from '../../modal/DialogModal';
import LogoutModal from '../../modal/LogoutModal';
import AboutModal from '../../modal/AboutModal';
import WorkbenchRoute from '../../../routing/WorkbenchRoute';
import ProfileMenu from '../../modal/ProfileMenu';

const {
    DIALOG,
} = routeNames;

class AppNavigation extends PureComponent {
    static propTypes = {
        defaultRouteName: PropTypes.string,
        navigator: PropTypes.instanceOf(Navigator),
        /** Id used for testing */
        testID: PropTypes.string,
    };

    render() {
        const {
            defaultRouteName,
            navigator,
            testID,
        } = this.props;

        const {
            themeStore,
            sessionStore,
            uiStore,
        } = rootStore;

        // Get styles
        const styles = getStyles();

        // pull the route from the URL
        const currentRouteName = navigator.match.params.route || defaultRouteName;
        const { dialogId } = navigator.match.params;
        const CurrentRoute = getRouteComponent(currentRouteName);
        // Always keep the side drawer open when in dialog page.
        const drawerOpen = currentRouteName === DIALOG;

        // pass along the url parameters to the specified route component
        const routeProps = {
            navigator,
            ...navigator.match.params,
            key: dialogId,
        };

        const notificationProps = {
            navigator,
            contextStyles: {
                button: styles.notificationButton,
                icon: styles.notificationIcon,
            },
        };
        const { lang } = serviceFactory;
        const { generic } = lang;
        // builds logout header
        // TODO: Hard coding styles because all of this needs to be moved.
        const links = (
            <Box contextStyles={ {
                container: styles.linksContainer,
            } }>
                <a
                    style={ { color: styles.notificationIcon.color, marginRight: '45px' } }
                    href="https://xaltmobilitynewuiux.featureupvote.com/"
                    target="_blank"
                    rel="noopener noreferrer">
                    { generic.giveYourFeedback }
                    <IconButton
                        style={ styles.notificationIcon }>
                        <RateReviewIcon />
                    </IconButton>
                </a>
                { sessionStore.notificationsAction && <NotificationsRoute { ...notificationProps } /> }
                <ProfileMenu key="profilemenu" />
            </Box>
        );

        // Construct layout props
        const layoutProps = {
            contextStyles: {
                container: styles.container,
                header: styles.header,
                content: styles.content,
                footer: styles.footer,
            },
        };

        if (testID) { layoutProps['data-test-id'] = `${testID}__layout`; }

        // Render application layout
        return (
            <Fragment>
                <LogoutModal uiStore={ uiStore } />
                <DialogModal uiStore={ uiStore } />
                <AboutModal uiStore={ uiStore } />
                <HolyGrailLayout
                    { ...layoutProps }
                    header={
                        <AppHeader>
                            <AppBar
                                contextStyles={ {
                                    toolbar: styles.toolbar,
                                } }
                                menu={ (
                                    <Image
                                        contextStyles={ {
                                            image: styles.menuImage,
                                        } }
                                        imageSrc={ themeStore.appTopLogo } />
                                ) }
                                title={ null }
                                toolbar={ links } />
                        </AppHeader>
                    }
                    content={ (
                        <div style={ styles.contentWrapper }>
                            { /*
                                Workbench route displays launchers in sidedrawer when we are in dialog route. Having it here looks weird.
                                Ideally Launchers Drawer should be part of Dialog Route, but whenever dialog changes we build the entire dialog page again.
                                When this happens, the Launchers Drawer will be recreated again. Whenever it is recreated, all the images for the launchers will be reloaded
                                and also the scroll position will be lost whenever there is a transition to different dialog. So, had to keep this logic here.
                            */ }
                            { drawerOpen && (
                                <div style={ styles.sideDrawer }>
                                    <WorkbenchRoute
                                        navigator={ navigator }
                                        showDrawer />
                                </div>
                            ) }
                            <div style={ styles.mainContent }>
                                <CurrentRoute { ...routeProps } />
                            </div>
                        </div>
                    ) }
                    footer={ <AppFooter /> } />
            </Fragment>
        );
    }
}

export default AppNavigation;
